<template>
  <div class="const_width">
    <div class="left_part">
      <div class="content_container">
        <v-skeleton-loader
            v-if="!teamDetail"
            type="image"
            height="186"
            tile
        ></v-skeleton-loader>
        <template v-if="teamDetail">
          <div class="td_header_top">
            <img class="th_header_logo"
                 :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${teamDetail.id}.png`" alt=""/>
            <div class="td_header_title">
              <div class="td_header_country">
<!--                <img class="td_header_country_img"
                     :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${teamDetail.countryId}.png` || ''"
                     alt="">-->
                <div>{{ teamDetail.country }}</div>
              </div>
              <div class="td_header_team_name">{{ teamDetail.name }}</div>
            </div>
          </div>
<!--          <div class="td_header_bottom">
            <div class="td_info_header _header _accent&#45;&#45;text">Teknik Direktör</div>
            <div class="td_info_header _header _accent&#45;&#45;text">Stat</div>
            <div class="td_info_header _header _accent&#45;&#45;text">Stat Kapasitesi</div>
            <div class="td_info_row">{{ teamDetail.managerName }}</div>
            <div class="td_info_row">{{ teamDetail.venueName }}</div>
            <div class="td_info_row">{{ teamDetail.venueCapacity }}</div>
          </div>-->
        </template>
        <v-tabs centered slider-size="3" v-model="tabModel" height="32" color="black">
          <v-tab class="text-capitalize" v-for="item in tabs" :key="item.id" :to="item.to">
            {{ item.text }}
          </v-tab>
        </v-tabs>
        <router-view/>
      </div>
    </div>
    <div class="right_part">
      <div class="match_detail_bilyoner_container">
        <a href="https://app.adjust.com/azvq2wg?redirect=https%3A%2F%2Fwww.bilyoner.com%2Fiddaa%3Futm_source%3Dfutbolverileri" target="_blank">
          <v-img
              max-width="313"
              max-height="630"
              width="100%"
              src="@/assets/bilyoner/premierLeague.png"
              lazy-src="@/assets/bilyoner/premierLeague.png"
          />
        </a>
      </div>
      <div class="content_container">
        <team-standings v-if="standings.length > 0" :standings="standings"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchTeam,
  fetchTeamResults,
  //fetchTeamSeasonId,
  //fetchTeamSeasons,
  fetchTeamStandings,
  fetchTeamStandingsSeason
} from "../../api";
import TeamStandings from "./TeamStandings";

export default {
  name: "TeamDetail",
  components: {TeamStandings},
  data() {
    return {
      team: null,
      seasonMatches: [],
      matches: [],
      standings: [],
      tabModel: 0,
      teamDetail: null,
      seasonId: null,
      tabs: [
        {text: 'Maçlar', to: {name: 'TeamDetail'}},
        {text: 'Fikstür', to: {name: 'TeamFixture2'}},
      ]
    }
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const teamD = await fetchTeam(this.$route.params.id)
      this.teamDetail= teamD.data.data
      /*const response = await fetchTeamSeasonId(this.$route.params.id);

      if (response.data.data.seasonGroups.length !== 0) {
        this.seasonId = response.data.data.seasonGroups[0].seasons[0].seasonId
      }*/

      const data = await fetchTeamResults(this.$route.params.id);
      //const data2 = await fetchTeamSeasons(this.$route.params.id);
      this.team = this.$route.params.id;
      this.matches = data.data.data.matchesByTime;
      this.seasonMatches = data.data.data.matchesByLeague;

      let seasons = [];
      this.seasonMatches.forEach(s => {
        seasons.push({ seasonId: s.seasonId, competitionName: s.league || null});
      });
      console.log("SEASONS",seasons)
        await this.fetchStandings(seasons[0]);
    },

    async fetchStandings(season) {
      if (season.competitionName) {
        console.log(1)
        const data = await fetchTeamStandingsSeason(this.$route.params.id,season.seasonId);
        console.log("STANDİNGS",data)
        if (data.data.data.groups[0]) {
          this.standings.push({
            seasonId: season.seasonId,
            standings: data.data.data.groups[0].rows
          })
          console.log("ST",this.standings)
        }
      } else {
        console.log(2)
        const data = await fetchTeamStandings(this.$route.params.id);
        if (data.data.data.groups[0]) {
          this.standings.push({
            seasonId: season.seasonId,
            standings: data.data.data.groups[0].rows
          })
        }
      }

    },
  },
}
</script>

<style scoped>
.td_header_title {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  padding: 0 15px;
}

.td_header_country {
  display: flex;
  font-size: 16px;
  color: #929292;
  align-self: end;
  place-items: center;
}

.td_header_team_name {
  font-size: 26px;
}

.td_header_bottom {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-template-rows: 23px 23px;
  margin-bottom: 15px;
}

.td_info_header, .td_info_row {
  text-align: center;
  line-height: 23px;
  color: #3e2d56;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.td_info_header:not(:first-child), .td_info_row:not(:first-child) {
  border-left: 1px solid #edf2f4;
}

.td_info_header {
  border-bottom: 1px solid #edf2f4;
  font-weight: bold;
  color: #ffffff;
}

.td_info_row {
  border-bottom: 1px solid #edf2f4;
}

.match_detail_bilyoner_container {
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 10px;
}

@media only screen and (min-width: 650px) {
  .td_header_top {
    display: grid;
    grid-template-columns: 90px auto;
    grid-template-rows: 90px;
    padding: 20px 15px 15px 15px;
  }

  .th_header_logo {
    width: 100%;
    height: auto;
    align-self: center
  }

  .td_header_country_img {
    width: 25px;
    height: 20px;
    margin-right: 5px;
  }

}

@media only screen and (max-width: 650px) {
  .td_header_top {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 90px auto;
    padding: 20px 15px 15px 15px;
  }

  .th_header_logo {
    width: 80px;
    height: 80px;
    align-self: center;
    margin: 0 auto;
  }

  .td_header_title {
    align-content: center;
  }

  .td_header_country div {
    margin-right: auto;
  }

  .td_header_country_img {
    margin-left: auto;
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }

  .td_header_team_name {
    text-align: center;
  }
}
</style>
