<template>
  <div>
    <h2 class="fv_title mt-0">Puan Durumu</h2>
    <div class="standing_container" v-for="(s, si) in standings" :key="si">
<!--      <div class="season_name _header _accent&#45;&#45;text">{{ s.standings[0].competitiontName }}</div>-->
      <div class="standing_header _header _accent--text">
        <div class="sh_num">#</div>
        <div class="sh_team_name">Takım</div>
        <div class="sh_value">O</div>
        <div class="sh_value">G</div>
        <div class="sh_value">B</div>
        <div class="sh_value">M</div>
        <div class="sh_value">P</div>
      </div>
      <div class="standing_row"
           v-for="(t, ti) in s.standings"
           :key="ti"
           :class="standingTeamNameClass(t.teamId)"
      >
        <div class="sh_num">{{ t.position }}</div>
        <div class="sh_team_name">
          <span @click="toTeamDetail(t.teamId)">{{ t.teamName }}</span>
        </div>
        <div class="sh_value">{{ t.played }}</div>
        <div class="sh_value">{{ t.win }}</div>
        <div class="sh_value">{{ t.draw }}</div>
        <div class="sh_value">{{ t.loss }}</div>
        <div class="sh_value">{{ t.points }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TeamStandings",
  props: ['standings'],
  data() {
    return {}
  },
  methods: {
    toTeamDetail(id) {
      let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
    standingTeamNameClass(id) {
      if (this.$parent.$data.team) {
        if (id === this.$parent.$data.team) {
          return 'blue--text';
        } else {
          return '';
        }
      }
    },
  },
}
</script>

<style scoped>
.sh_num, .sh_value {
  text-align: center;
}

.sh_team_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.season_name {
  font-weight: bold;
  line-height: 24px;
  font-size: 12px;
  color: #ffffff;
  padding: 0 10px;
  text-align: center;
}

.standing_container {
  margin-bottom: 15px;
}

.standing_row {
  display: grid;
  grid-template-columns: 24px minmax(60px, 300px) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto);
  grid-template-rows: 23px;
  line-height: 23px;
  border-bottom: 1px solid #edf2f4;
}

.standing_header {
  display: grid;
  grid-template-columns: 24px minmax(60px, 300px) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto);
  grid-template-rows: 23px;
  line-height: 23px;
  font-weight: bold;
  color: #ffffff;
  border-bottom: 1px solid #edf2f4;
}
</style>
